import React, { useCallback, useMemo } from 'react'
import languageMenuArrowLeft from 'src/figma/images/languageMenuArrowLeft'
import BorderRadius from 'src/figma/tokens/BorderRadius'
import FigmaColors from 'src/figma/tokens/FigmaColors'
import fontWeights from 'src/figma/tokens/fontWeights'
import Spacings from 'src/figma/tokens/Spacings'
import createKeyIndex from 'src/helpers/createKeyIndex'
import { getSelectedLanguage, isLanguageSelected, languages } from 'src/helpers/LanguagesHelper'
import useAppState from 'src/hooks/useAppState'
import { Language } from 'src/interfaces/LanguageType'
import ImageContainer from 'src/moesia/components/ImageContainer'
import StyledBox from 'src/moesia/components/StyledBox'
import SVGContainer from 'src/moesia/components/SVGContainer'
import TextContainer from 'src/moesia/components/TextContainer'
import styled, { css } from 'styled-components'

type Props = {
  setIsLanguageMenuOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const MobileNavigationLanguageMenu: React.FC<Props> = ({ setIsLanguageMenuOpen }) => {
  const { state, setState } = useAppState()

  const selectedLanguage = useMemo(() => getSelectedLanguage(state), [state])

  const onSelectLanguage = useCallback(
    (language: Language) => {
      setState({ ...state, language })
      setIsLanguageMenuOpen(false)
    },
    [setIsLanguageMenuOpen, setState, state]
  )

  return (
    <>
      <StyledBox onClick={() => setIsLanguageMenuOpen(false)}>
        <SVGContainer SVG={languageMenuArrowLeft} />
      </StyledBox>

      {languages.map(({ value, text, currentLangImg }, idx) => {
        const isSelectedLanguage = isLanguageSelected(value, selectedLanguage)

        return (
          <StyledBox
            onClick={() => onSelectLanguage(value)}
            key={createKeyIndex(idx, 'nav-item')}
            fullWidth
            fullPadding
            spacing={Spacings.small}
            direction="row"
            align="center"
            justify="space-between"
          >
            <StyledBox direction="row" gap={Spacings.small} align="center">
              <ImageContainer imageKey={currentLangImg} />
              <StyledTextContainer isSelected={isSelectedLanguage} textKey={text} />
            </StyledBox>
            <LanguageCircle isSelected={isSelectedLanguage} />
          </StyledBox>
        )
      })}
    </>
  )
}

export default React.memo(MobileNavigationLanguageMenu)

const LanguageCircle = styled(StyledBox)<{ isSelected: boolean }>`
  &&& {
    width: ${Spacings.min};
    height: ${Spacings.min};
    border-radius: ${BorderRadius.circle};
    background-color: ${FigmaColors.gray};

    ${({ isSelected }) =>
      isSelected &&
      css`
        width: ${Spacings.tiny};
        height: ${Spacings.tiny};
        background-color: ${FigmaColors.bhBlue};
      `}
  }
`

const StyledTextContainer = styled(TextContainer)<{ isSelected: boolean }>`
  span {
    font-weight: ${({ isSelected }) => isSelected && `${fontWeights.bold} !important`};
  }
`
