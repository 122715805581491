import styled from 'styled-components'
import React, { memo, useMemo } from 'react'
import DevHarbourLogo from './DevHarbourLogo'
import config from '../config'
import LanguageBar from 'src/components/LanguageBar'
import createKeyIndex from 'src/helpers/createKeyIndex'
import useTextVariant from 'src/hooks/useTextVariant'
import { Texts } from '../figma/helpers/TextRepository'
import Shadows from 'src/figma/tokens/Shadows'
import Spacings from 'src/figma/tokens/Spacings'
import StyledBox from 'src/moesia/components/StyledBox'
import TextContainer from 'src/moesia/components/TextContainer'
import { TERVEL_LANGUAGE_BAR_MIN_WIDTH, TERVEL_LOGO_MAX_HEIGHT, TERVEL_NAVIGATION_HEIGHT } from 'src/moesia/StrongHardCodedSizes'
import { useLocation, useNavigate } from 'react-router-dom'
import { PAGE_WRAPPER_MAX_WIDTH, REGULAR_TRANSITION } from 'src/moesia/Constants'
import useIsMobile from 'src/moesia/hooks/useIsMobile'
import { getNavigationItems, isSandbox, NavigationItems } from 'src/helpers/NavigationContent'
import FigmaColors from 'src/figma/tokens/FigmaColors'
import ZIndex from 'src/figma/tokens/ZIndex'

const DesktopNavigation: React.FC = () => {
  const { pathname } = useLocation()
  const isMobile = useIsMobile()
  const navigationItems: NavigationItems[] = useMemo(() => getNavigationItems(isMobile), [isMobile])
  const navLinkStyles = useTextVariant(Texts.JoinUs)
  const navigate = useNavigate()

  return (
    <NavigationWrapper fullWidth>
      <StyledBox fullWidth position="relative" align="center" justify="center">
        <Navigation fullWidth direction="row" align="center" justify="space-evenly">
          <StyledDevHarbourLogo />
          <NavigationItemsWrapper direction="row" justify="center" align="center">
            {!!navigationItems &&
              navigationItems.map(({ listItemTextKey, url }, idx) => (
                <NavigationItem
                  key={createKeyIndex(idx, 'nav-links')}
                  justify="center"
                  align="center"
                  active={pathname === url}
                  left
                  right
                  spacing={Spacings.medium}
                >
                  <StyledBox onClick={() => navigate(url)} align="center" justify="center" fullPadding spacing={Spacings.medium}>
                    {!!isSandbox(url) && <TextContainer textKey={listItemTextKey} style={navLinkStyles.style} text="Sandbox" />}
                    {!isSandbox(url) && <TextContainer textKey={listItemTextKey} style={navLinkStyles.style} />}
                  </StyledBox>
                </NavigationItem>
              ))}
          </NavigationItemsWrapper>
          <LanguageBarWrapper direction="row" gap={Spacings.medium} align="center" justify="center">
            {config.enabledLanguageBar && <LanguageBar popperBoxBackgroundColor={FigmaColors.cloud} buttonColor={navLinkStyles.style.color} />}
          </LanguageBarWrapper>
        </Navigation>
      </StyledBox>
    </NavigationWrapper>
  )
}
export default memo(DesktopNavigation)

const NavigationWrapper = styled(StyledBox)`
  z-index: ${ZIndex.high};
  background: ${FigmaColors.white};
  box-shadow: ${Shadows.baseMedium};
`

const Navigation = styled(StyledBox)`
  box-sizing: border-box;
  max-width: ${PAGE_WRAPPER_MAX_WIDTH};
  height: ${TERVEL_NAVIGATION_HEIGHT};
`

const StyledDevHarbourLogo = styled(DevHarbourLogo)`
  max-height: ${TERVEL_LOGO_MAX_HEIGHT};
  svg {
    max-height: ${TERVEL_LOGO_MAX_HEIGHT};
    align-self: center;
  }
`

const NavigationItemsWrapper = styled(StyledBox)`
  height: 100%;
`

const NavigationItem = styled(StyledBox)<{ active: boolean }>`
  height: 100%;
  cursor: pointer;
  box-sizing: border-box;
  border-bottom: ${({ active }) => (active ? `${Spacings.minimum} solid ${FigmaColors.bhBlue}` : `${Spacings.minimum} solid transparent`)} !important;
  transition: ${REGULAR_TRANSITION};
  &:hover {
    border-bottom: ${Spacings.minimum} solid ${FigmaColors.bhBlue} !important;
  }
`

const LanguageBarWrapper = styled(StyledBox)`
  min-width: ${TERVEL_LANGUAGE_BAR_MIN_WIDTH};
`
