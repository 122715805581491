export default {
  linearBlue: 'linear-gradient(180deg, #7dd6c0 0%, #059ba5 28.44%, #086a8d 65.42%, rgba(0, 29, 73, 0.9) 100%)',
  secondaryButtonColor: '#C9E0E9',
  primaryButtonHoverColor: '#69A6BD',
  tertiaryButtonBackgroundColor: '#F1CCCE',
  tertiaryButtonBorderColor: '#E30733',
  veryLightBlue: '#F5FCFF',
  darkBlue: '#003366',
  lightStone: '#E9E9E9',
  lighterRed: '#FF4B55'
}
