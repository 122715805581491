const nestedRoutePaths = {
  aboutUs: '/company#aboutUs',
  team: '/company#leadership',
  news: '/company#news',
  howCanWeHelp: '/solutions#canWeHelp',
  hireADeveloper: '/solutions#hireDeveloper',
  buildProject: '/solutions#buildProject'
}

export default nestedRoutePaths
