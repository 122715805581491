import { memo, useMemo } from 'react'
import styled from 'styled-components'
import BorderRadius from 'src/figma/tokens/BorderRadius'
import Spacings from 'src/figma/tokens/Spacings'
import FigmaColors from 'src/figma/tokens/FigmaColors'
import StyledBox from '../moesia/components/StyledBox'
import { getFooterLinksItems } from '../interfaces/FooterSocialLinks'
import StyledLink from './StyledLink'
import SVGContainer from '../moesia/components/SVGContainer'

const FooterSocialLinksComponent = () => {
  const footerLinksItems = useMemo(() => getFooterLinksItems(), [])

  return (
    <StyledBox direction="row" align="center" top={Spacings.max} bottom={Spacings.min}>
      {!!footerLinksItems &&
        footerLinksItems.map(({ svg, url }, idx) => (
          <StyledLink openInNewTab key={idx} href={url} right={Spacings.small}>
            <StyledSVGContainer SVG={svg} />
          </StyledLink>
        ))}
    </StyledBox>
  )
}

const StyledSVGContainer = styled(SVGContainer)`
  color: ${FigmaColors.white};

  svg {
    border-radius: ${BorderRadius.rounded};
  }

  &:hover {
    color: ${FigmaColors.green};
  }
`

export default memo(FooterSocialLinksComponent)
