import styled from 'styled-components'

import ButtonPrimaryActionCss from './ButtonPrimaryActionCss'

interface ButtonPrimaryActionProps {
  children: any
  [propName: string]: {}
}

// Extend the below as needed
const ButtonPrimaryActionStyled = styled.div<ButtonPrimaryActionProps>`
  ${ButtonPrimaryActionCss};
`

export default ButtonPrimaryActionStyled
