import React from 'react'
import { CallbackRef } from 'use-is-in-viewport'
import { RowFlexbox, Flexbox } from './StyleComponents'

type Props = {
  direction?: 'column' | 'row'
  className?: string
  maxWidth?: string
  justify?: 'baseline' | 'center' | 'flex-end' | 'flex-start'
  anchorId?: string
  ref?: CallbackRef
  align?: 'baseline' | 'center' | 'flex-end' | 'flex-start' | 'inherit' | 'stretch'
  marginBottom?: string
  gap?: string
  fullWidth?: boolean
  children: React.ReactNode
}

const SectionWrapper: React.FC<Props> = ({
  direction: _direction,
  className,
  maxWidth,
  anchorId,
  justify,
  ref,
  align,
  marginBottom,
  gap,
  fullWidth,
  ...props
}) => {
  let element
  switch (true) {
    case _direction === 'row': {
      element = RowFlexbox
      break
    }
    default:
      element = Flexbox
  }
  const title = React.createElement(
    element,
    {
      className: className,
      id: anchorId,
      style: {
        ...(fullWidth && { width: '100%' }),
        maxWidth: maxWidth,
        justifyContent: justify,
        alignItems: align,
        marginBottom: marginBottom,
        gap: gap
      },
      ref: ref
    },
    props.children
  )
  return title
}

export default SectionWrapper
