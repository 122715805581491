import useIsMobile from 'src/moesia/hooks/useIsMobile'
import { TextType } from '../interfaces/FigmaTypes'
import FigmaVariant from '../interfaces/FigmaVariant'

export default function useTextVariant(textKey: TextType) {
  const isMobile = useIsMobile()
  let variantStyle = isMobile ? textKey.variants[FigmaVariant.MOBILE] : textKey.variants[FigmaVariant.DESKTOP]
  if (!variantStyle) variantStyle = textKey.variants[FigmaVariant.DESKTOP] || textKey.variants[FigmaVariant.MOBILE]
  return variantStyle
}
