import Slide from '@mui/material/Slide'
import React, { useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Texts } from 'src/figma/helpers/TextRepository'
import navigationArrowRight from 'src/figma/images/navigationArrowRight'
import navigationLanguageIcon from 'src/figma/images/navigationLanguageIcon'
import fontWeights from 'src/figma/tokens/fontWeights'
import Spacings from 'src/figma/tokens/Spacings'
import createKeyIndex from 'src/helpers/createKeyIndex'
import scrollToTop from 'src/helpers/ScrollToTopHelper'
import { getNavigationItems, isSandbox, NavigationItems } from 'src/helpers/NavigationContent'
import StyledBox from 'src/moesia/components/StyledBox'
import SVGContainer from 'src/moesia/components/SVGContainer'
import TextContainer from 'src/moesia/components/TextContainer'
import useIsMobile from 'src/moesia/hooks/useIsMobile'
import { TERVEL_NAVIGATION_HEIGHT } from 'src/moesia/StrongHardCodedSizes'
import styled from 'styled-components'
import MobileNavigationLanguageMenu from './MobileNavigationLanguageMenu'
import FigmaColors from 'src/figma/tokens/FigmaColors'

const MobileNavigationMenu: React.FC = () => {
  const [isLanguageMenuOpen, setIsLanguageMenuOpen] = useState(false)
  const { pathname } = useLocation()
  const isMobile = useIsMobile()
  const navigate = useNavigate()
  const navigationItems: NavigationItems[] = useMemo(() => getNavigationItems(isMobile), [isMobile])
  const handleNavigate = (url: string) => {
    navigate(url)
    scrollToTop()
  }
  const handleOpenLanguageMenu = () => {
    setIsLanguageMenuOpen(true)
  }

  return (
    <>
      {!isLanguageMenuOpen && (
        <>
          <StyledBox fullWidth>
            {!!navigationItems &&
              navigationItems.map(({ listItemTextKey, url, icon }, idx) => (
                <MenuItem
                  onClick={() => handleNavigate(url)}
                  key={createKeyIndex(idx, 'nav-item')}
                  active={pathname === url}
                  fullWidth
                  fullPadding
                  spacing={Spacings.small}
                  gap={Spacings.small}
                  direction="row"
                  align="center"
                >
                  <SVGContainer SVG={icon} />
                  {!isSandbox(url) && <TextContainer textKey={listItemTextKey} />}
                  {!!isSandbox(url) && <TextContainer textKey={listItemTextKey} text="Sandbox" />}
                </MenuItem>
              ))}
          </StyledBox>
          <LanguageMenuButtonWrapper
            onClick={handleOpenLanguageMenu}
            fullWidth
            fullPadding
            spacing={Spacings.small}
            direction="row"
            align="center"
            justify="space-between"
          >
            <StyledBox direction="row" gap={Spacings.small} align="center">
              <SVGContainer SVG={navigationLanguageIcon} />
              <TextContainer textKey={Texts.NavigationLanguageTitle} />
            </StyledBox>
            <StyledBox>
              <SVGContainer SVG={navigationArrowRight} />
            </StyledBox>
          </LanguageMenuButtonWrapper>
        </>
      )}
      <StyledSlide direction="left" in={isLanguageMenuOpen} mountOnEnter unmountOnExit>
        <Wrapper>
          <MobileNavigationLanguageMenu setIsLanguageMenuOpen={setIsLanguageMenuOpen} />
        </Wrapper>
      </StyledSlide>
    </>
  )
}

export default React.memo(MobileNavigationMenu)

const MenuItem = styled(StyledBox)<{ active: boolean }>`
  span {
    font-weight: ${({ active }) => active && `${fontWeights.bold} !important`};
  }
`

const LanguageMenuButtonWrapper = styled(StyledBox)`
  position: fixed;
  left: 0;
  bottom: 0;
`

const StyledSlide = styled(Slide)`
  // This fixes an animation problem when closing the Language menu
  position: absolute;
`

const Wrapper = styled(StyledBox)`
  // The Wrapper is too big with fullWidth and gets out of the screen.
  width: 95%;
  height: calc(100vh - (${TERVEL_NAVIGATION_HEIGHT}));
  background-color: ${FigmaColors.white};
`
