import { Link, Typography } from '@material-ui/core'
import routePaths from '../config/RoutePaths'

const Copyright = (year: number) => (
  <Typography align="center">
    {'Copyright © '}
    <Link color="inherit" href={routePaths.entry}>
      {'Dev-Harbour '}
    </Link>
    {` ${year}.`}
  </Typography>
)

export default Copyright
