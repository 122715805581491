import { createTheme } from '@material-ui/core/styles'
import { red } from '@material-ui/core/colors'
import FigmaColors from './figma/tokens/FigmaColors'

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: '#556cd6',
      dark: FigmaColors.bhBlueDark
    },
    secondary: {
      main: '#19857b',
      dark: FigmaColors.decorativeMint
    },
    success: {
      main: '#00CC8B'
    },
    error: {
      main: red.A400
    },
    background: {
      default: '#fff'
    }
  },
  overrides: {
    MuiContainer: {
      root: {
        padding: '0px !important'
      },
      maxWidthLg: {
        maxWidth: '100% !important'
      }
    },
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: 'white'
      }
    },
    MuiListItem: {
      root: {
        paddingTop: '0px !important',
        paddingBottom: '0px !important'
      }
    },
    MuiPaper: {
      root: {
        '@media(max-width: 950px)': {
          top: '455px !important'
        }
      }
    }
  }
})

export default theme
