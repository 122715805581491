import routePaths from './config/RoutePaths'
import { COMPANY, CONTACT_US, ERROR_404, GET_IN_TOUCH, HOME, JOIN_US, PORTFOLIO, SANDBOX, SOLUTIONS, UNDER_CONSTRUCTION } from './constants/routeNames'
import loadable from '@loadable/component'
import DevHarbourLoader from './components/DevHarbourLoader'
import { WebsiteEntryType } from './enums/GoogleAnalyticsEnums'
import GATrackTraffic from './components/GATrackTraffic'

const HomeContainer = loadable(() => import('./screens/HomeContainer'), {
  fallback: <DevHarbourLoader />
})

const CompanyContainer = loadable(() => import('./screens/CompanyContainer'), {
  fallback: <DevHarbourLoader />
})

const SolutionsContainer = loadable(() => import('./screens/SolutionsContainer'), {
  fallback: <DevHarbourLoader />
})

const PortfolioContainer = loadable(() => import('./screens/PortfolioContainer'), {
  fallback: <DevHarbourLoader />
})

const ContactUsContainer = loadable(() => import('./screens/ContactUsContainer'), {
  fallback: <DevHarbourLoader />
})

const Sandbox = loadable(() => import('./screens/SandboxContainer'), {
  fallback: <DevHarbourLoader />
})

const Error404Component = loadable(() => import('./components/Error404Component'), {
  fallback: <DevHarbourLoader />
})

const UnderConstructionComponent = loadable(() => import('./components/UnderConstructionComponent'), {
  fallback: <DevHarbourLoader />
})

const RouterPublicRoutes = [
  { path: routePaths.entry, component: HomeContainer, title: HOME },
  { path: routePaths.company, component: CompanyContainer, title: COMPANY },
  { path: routePaths.solutions, component: SolutionsContainer, title: SOLUTIONS },
  { path: routePaths.portfolio, component: PortfolioContainer, title: PORTFOLIO },
  { path: routePaths.joinUs, component: PortfolioContainer, title: JOIN_US },
  { path: routePaths.contactUs, component: ContactUsContainer, title: CONTACT_US },
  { path: routePaths.getInTouch, component: PortfolioContainer, title: GET_IN_TOUCH },
  { path: routePaths.sandbox, component: Sandbox, title: SANDBOX },
  { path: routePaths.error404Page, component: Error404Component, title: ERROR_404 },
  { path: routePaths.underConstructionPage, component: UnderConstructionComponent, title: UNDER_CONSTRUCTION },
  { path: '*', component: Error404Component, title: ERROR_404 },
  { path: routePaths.redirectedFromJobsBg, component: () => <GATrackTraffic search={`typeEntry=${WebsiteEntryType.JOBS_BG}`} />, title: HOME },
  { path: routePaths.redirectedFromDevBg, component: () => <GATrackTraffic search={`typeEntry=${WebsiteEntryType.DEV_BG}`} />, title: HOME },
  { path: routePaths.redirectedFromRecommendation, component: () => <GATrackTraffic search={`typeEntry=${WebsiteEntryType.RECOMMENDATION}`} />, title: HOME }
]

export default RouterPublicRoutes
