import App from './App'
import theme from './theme'
import ReactDOM from 'react-dom/client'
import ErrorBoundary from './components/ErrorBoundary'
import { ThemeProvider } from '@material-ui/core/styles'
import config from './config'
import { Integrations } from '@sentry/tracing'
import * as Sentry from '@sentry/react'
import { QueryClient, QueryClientProvider } from 'react-query'

const queryClient = new QueryClient({ defaultOptions: { queries: { staleTime: Infinity } } })

Sentry.init({
  dsn: config.sentryDSN,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
  debug: config.enableSentry,
  enabled: config.enableSentry,
  environment: config.name
})

const root = document.getElementById('root')

if (!!root) {
  ReactDOM.createRoot(root).render(
    <ErrorBoundary>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </QueryClientProvider>
    </ErrorBoundary>
  )
}
