import Cookies from 'js-cookie'
import React, { useContext } from 'react'
import { Language } from '../interfaces/LanguageType'

export type State = {
  language: Language
  hasCookies: boolean
}

export const DEFAULT_STATE: State = {
  language: 'en',
  hasCookies: false
}

export const Context = React.createContext<{
  state: State
  setState: (state: State) => void
}>({
  state: DEFAULT_STATE,
  setState: () => ({})
})

export const useAppState = (defaultState?: Partial<State>) => {
  const language = defaultState?.language || DEFAULT_STATE.language
  // eslint-disable-next-line prefer-const
  let [state, setState] = React.useState<State>({
    ...DEFAULT_STATE,
    language,
    hasCookies: Boolean(Cookies.get('dev-harbour-cookie'))
  })
  return { state, setState }
}
export default () => useContext(Context)
