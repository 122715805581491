/* eslint-disable max-len */
export const typeScriptFix = 1

//Used in SplitRowComponent
export const ICON_TABLET_VIEW_WIDTH = '60px'
export const ICON_TABLET_VIEW_HEIGHT = '50px'

//TERVEL HomeContainer
export const TERVEL_HOME_ABOUT_US_HEIGHT = '650px'

//TERVEL CompanyLeadershipCard
export const TERVEL_DESKTOP_COMPANY_LEADERSHIP_CARD_SIZE = '300px'
export const TERVEL_MOBILE_COMPANY_LEADERSHIP_CARD_SIZE = '220px'
export const TERVEL_COMPANY_LEADERSHIP_AVATAR_HEIGHT_SIZE = 300
export const TERVEL_DEFAULT_CENTERED_CARD = 1
export const TERVEL_CAROUSEL_LEFT_RIGHT_PADDING = 50
export const TERVEL_CAROUSEL_TOP_BOTTOM_PADDING = 130
export const TERVEL_DEFAULT_ITEMS_TO_SHOW_MOBILE = 1
export const TERVEL_DEFAULT_ITEMS_TO_SHOW_DESKTOP = 3
export const TERVEL_TWO_ITEMS_TO_SHOW = 2
export const TERVEL_CAROUSEL_TOP_BOTTOM_PADDING_MOBILE = 50
export const TERVEL_CAROUSEL_LEFT_RIGHT_PADDING_MOBILE = 20

//TERVEL ModalWeValueYourPrivacy
export const TERVEL_PRIVACY_MODAL_WIDTH_MOBILE = '325px'
export const TERVEL_PRIVACY_MODAL_WIDTH_DESKTOP = '900px'
export const TERVEL_PRIVACY_MODAL_HEIGHT = '400px'

//TERVEL Error404Component
export const TERVEL_ERROR_404_DESKTOP_BUTTON_WIDTH = '15%'
export const TERVEL_ERROR_404_MOBILE_BUTTON_WIDTH = '40%'

//TERVEL Sandbox
export const TERVEL_SANDBOX_BUTTON_WIDTH_DESKTOP = '15%'
export const TERVEL_SANDBOX_BUTTON_WIDTH_MOBILE = '60%'

//TERVEL CompAboutUsTextWrapper
export const ABOUT_US_TEXT_WRAPPER_MAX_WIDTH_DESKTOP = '60%'
export const ABOUT_US_TEXT_WRAPPER_MAX_WIDTH_MOBILE = '90%'
