import { EN, SV, BG } from 'src/Constants'
import { State } from 'src/hooks/useAppState'
import { Images } from '../figma/helpers/imageHelper'
import { Texts } from '../figma/helpers/TextRepository'
import { Language, LanguageType } from '../interfaces/LanguageType'

export const languages: LanguageType[] = [
  {
    value: EN,
    text: Texts.LanguageOptionEnglish,
    currentLangSmallImg: Images.ukFlag,
    currentLangImg: Images.ukFlag
  },
  {
    value: SV,
    text: Texts.LanguageOptionSwedish,
    currentLangSmallImg: Images.swedenFlag,
    currentLangImg: Images.swedenFlag
  },
  {
    value: BG,
    text: Texts.LanguageOptionBulgarian,
    currentLangSmallImg: Images.bulgariaFlag,
    currentLangImg: Images.bulgariaFlag
  }
]

export const isLanguageSelected = (currentLanguage: Language, selectedLanguage: LanguageType): boolean => currentLanguage === selectedLanguage.value

export const getSelectedLanguage = (state: State) => languages.find((lang) => lang.value === state.language) || languages[0]
