import * as React from 'react'

import ButtonPrimaryDefaultStyled from './ButtonPrimaryDefaultStyled'

type ButtonPrimaryDefaultProps = any

const ButtonPrimaryDefault: React.FC<ButtonPrimaryDefaultProps> = (props) => (
  <ButtonPrimaryDefaultStyled {...props}>{props.children ? props.children : 'Company'}</ButtonPrimaryDefaultStyled>
)

export default ButtonPrimaryDefault
