import ClickAwayListener from '@mui/material/ClickAwayListener'
import Popper from '@mui/material/Popper'
import * as React from 'react'
import { memo, useState } from 'react'
import FigmaColors from 'src/figma/tokens/FigmaColors'
import Shadows from 'src/figma/tokens/Shadows'
import Spacings from 'src/figma/tokens/Spacings'
import styled, { css } from 'styled-components'
import { FigmaImageType, TextType } from '../interfaces/FigmaTypes'
import ImageContainer from './ImageContainer'
import StyledBox from './StyledBox'
import TextContainer from './TextContainer'

type Props = {
  children: React.ReactNode
  buttonTextKey: TextType
  buttonText?: string
  buttonImg?: FigmaImageType
  visible: boolean
  onOpen: () => void
  handleClose?: (event: MouseEvent | TouchEvent) => void
  popperBoxBackgroundColor?: string
  buttonColor?: string
}

const DevPopperExperimental = ({
  children,
  buttonTextKey,
  buttonText,
  buttonImg,
  visible,
  onOpen,
  handleClose,
  popperBoxBackgroundColor,
  buttonColor
}: Props) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

  const handleOnClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAnchorEl(event.currentTarget)
    onOpen()
  }
  return (
    <StyledBox>
      <ButtonBox
        fullWidth
        direction="row"
        gap={Spacings.small}
        align="center"
        justify="space-between"
        onClick={handleOnClick}
        buttonColor={buttonColor}
      >
        {!!buttonImg && <StyledImageContainer imageKey={buttonImg} />}
        <TextContainer textKey={buttonTextKey} text={buttonText} />
      </ButtonBox>
      <Popper open={visible} anchorEl={anchorEl} disablePortal>
        <ClickAwayListener onClickAway={(e) => handleClose?.(e)}>
          <PopperBox backgroundColor={popperBoxBackgroundColor}>{children}</PopperBox>
        </ClickAwayListener>
      </Popper>
    </StyledBox>
  )
}

export default memo(DevPopperExperimental)

const StyledImageContainer = styled(ImageContainer)`
  max-height: ${Spacings.medium} !important;
  min-height: auto !important;
  width: ${Spacings.large};
`

const ButtonBox = styled(StyledBox)<{ buttonColor?: string }>`
  cursor: pointer;
  transition: all 0.5s ease-in-out;
  &:hover {
    transform: scale(1.1);
  }
  ${({ buttonColor }) =>
    buttonColor &&
    css`
      span {
        color: ${buttonColor} !important;
      }
    `}
`

const PopperBox = styled(StyledBox)<{ backgroundColor: string }>`
  border-radius: ${Spacings.tiny};
  box-shadow: ${Shadows.hoverMedium};
  background-color: ${({ backgroundColor }) => (!!backgroundColor ? backgroundColor : FigmaColors.white)};
`
