import styled from '@emotion/styled'
import Cookies from 'js-cookie'
import React, { useState } from 'react'
import { Transition } from 'react-transition-group'
import { TransitionStatus } from 'react-transition-group/Transition'
import { Texts } from 'src/figma/helpers/TextRepository'
import BorderRadius from 'src/figma/tokens/BorderRadius'
import FigmaColors from 'src/figma/tokens/FigmaColors'
import Shadows from 'src/figma/tokens/Shadows'
import Spacings from 'src/figma/tokens/Spacings'
import ZIndex from 'src/figma/tokens/ZIndex'
import StyledBox from 'src/moesia/components/StyledBox'
import SubmitButton from 'src/moesia/components/SubmitButton'
import TextContainer from 'src/moesia/components/TextContainer'
import CookiePolicy from '../assets/PrivacyPolicyTermsFiles/Cookie_Policy_for_Dev-Harbour.pdf'
import useAppState from 'src/hooks/useAppState'
import useIsMobile from 'src/moesia/hooks/useIsMobile'
import { REGULAR_TRANSITION } from 'src/moesia/Constants'
import MediaQueries from 'src/figma/tokens/MediaQueries'

const COOKIES_EXPIRATION_PERIOD = 365 //one year
const COOKIES_FADE_OUT_ANIMATION_TIME = 300 // 0.3 sec

const GDPRModal: React.FC = () => {
  const { state, setState } = useAppState()
  const { hasCookies } = state
  const [showCookieMessage, setShowCookieMessage] = useState<boolean>(!hasCookies)
  const isMobile = useIsMobile()

  const handleAcceptCookies = () => {
    setShowCookieMessage(false)
    Cookies.set('dev-harbour-cookie', 'accepted', { expires: COOKIES_EXPIRATION_PERIOD })
    setTimeout(() => {
      const newState = { ...state, hasCookies: true }
      setState(newState)
    }, COOKIES_FADE_OUT_ANIMATION_TIME)
  }

  const handleOpenCookiePolicy = () => {
    window.open(CookiePolicy)
  }

  return (
    <Transition in={showCookieMessage} timeout={0}>
      {(status) => (
        <Wrapper fullWidth fullPadding spacing={Spacings.tiny} align="center" status={status}>
          <Container fullPadding direction={!isMobile && 'row'} gap={Spacings.small} justify="space-between" align="center">
            <TextWrapper direction={!isMobile && 'row'} gap={Spacings.min}>
              <TextContainer textKey={Texts.CookiesPolicy} />
              <StyledBox onClick={handleOpenCookiePolicy} pointer>
                <TextContainer textKey={Texts.CookiesPolicyLink} />
              </StyledBox>
            </TextWrapper>
            <StyledBox>
              <StyledSubmitButton variant="primary" onClick={handleAcceptCookies} textKey={Texts.AcceptCookies} />
            </StyledBox>
          </Container>
        </Wrapper>
      )}
    </Transition>
  )
}

export default GDPRModal

const Container = styled(StyledBox)`
  border-radius: ${BorderRadius.soft};
  background-color: ${FigmaColors.baseWhite};
  box-shadow: ${Shadows.baseHeavy};
`

const Wrapper = styled(StyledBox)<{ status: TransitionStatus }>`
  position: fixed;
  bottom: ${Spacings.small};
  z-index: ${ZIndex.heaven};
  opacity: ${(props) => (props.status === 'entered' ? 1 : 0)};
  pointer-events: ${(props) => (props.status === 'exited' ? 'none' : 'initial')};
  transition: ${REGULAR_TRANSITION};
  box-sizing: border-box;
`
const TextWrapper = styled(StyledBox)`
  //These styled are to make the two text containers to be in the same line like one sentence
  display: inline;
  div {
    display: inline;
    span {
      display: inline;
    }
  }
`
const StyledSubmitButton = styled(SubmitButton)`
  span {
    white-space: nowrap;
  }
`
