import styled from 'styled-components'
import routePaths from 'src/config/RoutePaths'
import Spacings from 'src/figma/tokens/Spacings'
import { Images } from 'src/figma/helpers/imageHelper'
import { Texts } from 'src/figma/helpers/TextRepository'
import StyledBox from 'src/moesia/components/StyledBox'
import NavigationMotion from './NavigationMotion'
import ImageContainer from 'src/moesia/components/ImageContainer'
import SubmitButton from 'src/moesia/components/SubmitButton'
import useIsMobile from 'src/moesia/hooks/useIsMobile'
import TextContainer from 'src/moesia/components/TextContainer'

const SomethingWentWrongComponent = () => {
  const isMobile = useIsMobile()
  const handleEntryPath = () => (window.location.pathname = routePaths.entry)

  return (
    <NavigationMotion>
      <Wrapper fullWidth fullPadding gap={Spacings.large} align="center" justify="center">
        <ImageContainer imageKey={isMobile ? Images.somethingWentWrongPhotoMobile : Images.somethingWentWrongPhoto} minHeight />
        <StyledBox gap={Spacings.small} align="center" alignText="center" justify="center">
          <TextContainer textKey={Texts.SomethingWrongHeading} />
          <TextContainer textKey={Texts.SomethingWrongSubheading} />
        </StyledBox>
        <SubmitButton textKey={Texts.HomePageButtonText} variant="primary" onClick={handleEntryPath} />
      </Wrapper>
    </NavigationMotion>
  )
}

export default SomethingWentWrongComponent

const Wrapper = styled(StyledBox)`
  height: 100vh;
  box-sizing: border-box;
`
