import React, { memo } from 'react'
import StyledBox from '../moesia/components/StyledBox'
import { Images } from '../figma/helpers/imageHelper'
import Spacings from 'src/figma/tokens/Spacings'
import styled from 'styled-components'
import HomeHeaderTextComponent from './HomeHeaderTextComponent'
import useIsMobile from 'src/moesia/hooks/useIsMobile'
import ImageContainer from 'src/moesia/components/ImageContainer'

const HomeLandingDesktop: React.FC = () => {
  const isMobile = useIsMobile()

  return (
    <StyledBox fullWidth direction="row" justify="space-between" align="center" top spacing={Spacings.big}>
      {!isMobile && <StyledImageContainer $isRotated={false} imageKey={Images.elementLeft} />}
      <HomeHeaderTextComponent />
      {!isMobile && <StyledImageContainer $isRotated={true} imageKey={Images.elementLeft} />}
    </StyledBox>
  )
}

export default memo(HomeLandingDesktop)

const StyledImageContainer = styled(ImageContainer)<{ $isRotated: boolean }>`
  transform: ${({ $isRotated }) => $isRotated && 'rotate(180deg)'};
  align-self: center;
`
