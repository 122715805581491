export const defaultConfiguration = {
  name: 'default',
  baseUrl: process.env.REACT_APP_BACKOFFICE_API_URL,
  gaTrackID: 'G-N1CBCF84K2',
  ga4MeasurementID: 'G-61VMJL1WW6',
  enabledCardAnimation: false,
  enabledRegister: false,
  enabledCookies: false,
  enabledSandbox: false,
  enabledLanguageBar: true,
  enableLinkedInLink: true,
  sentryDSN: 'https://6b411d244ced467285bf0cc8f1a0b2e8@o4504524943130624.ingest.sentry.io/4504812903071744',
  enableSentry: false
}

const local = {
  ...defaultConfiguration,
  name: 'local',
  ga4MeasurementID: 'G-6KHEFXBL5X',
  enabledCardAnimation: true,
  enabledRegister: true,
  enabledLanguageBar: true,
  enableSentry: false
} as typeof defaultConfiguration

export const development = {
  ...defaultConfiguration,
  name: 'development',
  ga4MeasurementID: 'G-6KHEFXBL5X',
  enabledCardAnimation: true,
  enabledCookies: false,
  enabledLanguageBar: true,
  enableSentry: true,
  baseUrl: 'https://api.bh-studio.com:8080/'
} as typeof defaultConfiguration

export const production = {
  ...defaultConfiguration,
  name: 'production',
  baseUrl: 'https://api.bh-studio.com:8080/',
  gaTrackID: 'G-61VMJL1WW6',
  ga4MeasurementID: 'G-61VMJL1WW6',
  sentryDSN: 'https://6b411d244ced467285bf0cc8f1a0b2e8@o4504524943130624.ingest.sentry.io/4504812903071744',
  enableSentry: true
} as typeof defaultConfiguration

export default {
  local,
  development,
  production
} as { [key: string]: typeof defaultConfiguration }

export const DEFAULT_ENV = 'production'

export type Config = typeof defaultConfiguration
