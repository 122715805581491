import { CircularProgress } from '@material-ui/core'
import React, { useState } from 'react'
import { useNavigate } from 'react-router'
import styled from 'styled-components'
import ButtonPrimaryAction from '../figma/elements/Button/Primary/Action/ButtonPrimaryAction'
import ButtonPrimaryDefault from '../figma/elements/Button/Primary/Default/ButtonPrimaryDefault'
import ButtonPrimaryHover from '../figma/elements/Button/Primary/Hover/ButtonPrimaryHover'

import FigmaColors from 'src/figma/tokens/FigmaColors'
import Spacings from 'src/figma/tokens/Spacings'
type Variant = 'primary' | 'secondary' | 'tertiary'
type Props = {
  variant?: Variant
  disabled?: boolean
  to: string
  style?: { [name: string]: number | string }
  className?: string
  pressed?: boolean
  loading?: boolean
  focus?: boolean
  onClick?: () => void
  active?: boolean
  children: React.ReactNode
}
const NavLinkButton: React.FC<Props> = (props) => {
  const { variant, children, to, className, pressed, loading, focus, onClick, active = false } = props

  const navigate = useNavigate()

  let disabled = props?.disabled

  const { style } = props

  const [hover, setHover] = useState(false)

  const element = getButtonElement({ variant, disabled, hover, pressed, focus, active })

  const classNames = `${className ? className : ''}${disabled ? ' disabled' : ''} `

  if (loading) disabled = loading

  const onNavClick = () => {
    navigate(to)
    return onClick
  }

  return (
    <HTMLButton
      className={classNames}
      disabled={disabled}
      type="submit"
      onClick={onNavClick}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {loading ? (
        <LoaderWrapper>
          <CircularProgress />
        </LoaderWrapper>
      ) : (
        React.createElement(element, { style }, children)
      )}
    </HTMLButton>
  )
}
const LoaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: ${Spacings.tiny} 0;
  .MuiCircularProgress-circle {
    color: ${FigmaColors.baseGarden};
  }
`
const HTMLButton = styled.button`
  padding: 0 0;
  border: none;
  background-color: transparent;
  cursor: pointer;
  white-space: nowrap;
  &.disabled {
    cursor: default;
  }
  &:focus {
    outline: 0;
  }
`
const getButtonElement = ({
  variant = 'primary',
  disabled,
  hover,
  active
}: {
  variant?: Variant
  disabled?: boolean
  hover?: boolean
  pressed?: boolean
  focus?: boolean
  active?: boolean
}) => {
  if (variant === 'primary' && !disabled && active) return ButtonPrimaryAction
  if (variant === 'primary' && !disabled && !hover) return ButtonPrimaryDefault

  if (variant === 'primary' && hover) return ButtonPrimaryHover

  return ButtonPrimaryDefault
}
export default NavLinkButton
