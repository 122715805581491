import Configurations from './Configurations'

const { NODE_ENV, REACT_APP_CLIENT_CONFIG } = process.env

export const getEnv = (hostname?: string) => {
  if (!hostname && typeof window !== 'undefined') hostname = window.location.hostname

  if (!!REACT_APP_CLIENT_CONFIG) return REACT_APP_CLIENT_CONFIG
  else if (hostname === 'dev-harbour.com') return 'production'
  else if (hostname === 'dev-harbor.bg') return 'production'
  else if (hostname === 'dev.bh-studio.com') return 'development'
  else if (hostname === 'stage.bh-studio.com') return 'stage'
  else if (hostname === 'localhost') return 'local'

  if (typeof process !== undefined && process.env.JEST_WORKER_ID !== undefined) return 'test'
  return NODE_ENV || 'development'
}

const env = getEnv()

const config = Configurations[env] || Configurations.production

export default config
