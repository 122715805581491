import { Texts } from '../figma/helpers/TextRepository'
import routePaths from '../config/RoutePaths'
import { TextType } from '../interfaces/FigmaTypes'
import config from '../config'
import navigationCompanyIcon from 'src/figma/images/navigationCompanyIcon'
import navigationSolutionsIcon from 'src/figma/images/navigationSolutionsIcon'
import navigationContactIcon from 'src/figma/images/navigationContactIcon'
import { SVGProps } from 'react'
import navigationHomeIcon from 'src/figma/images/navigationHomeIcon'

export interface NavigationItems {
  listItemTextKey: TextType
  url: string
  icon: (props: SVGProps<SVGSVGElement>) => JSX.Element
}

//SUPPORT CONFIGURATION AND FEATURE FLAGS
export const getNavigationItems = (isMobile: boolean): NavigationItems[] =>
  [
    isMobile && {
      listItemTextKey: Texts.NavigationHomeTitle,
      url: routePaths.entry,
      icon: navigationHomeIcon
    },
    {
      listItemTextKey: Texts.NavigationCompanyTitle,
      url: routePaths.company,
      icon: navigationCompanyIcon
    },
    {
      listItemTextKey: Texts.NavigationSolutionsTitle,
      url: routePaths.solutions,
      icon: navigationSolutionsIcon
    },
    {
      listItemTextKey: Texts.NavigationContactUsTitle,
      url: routePaths.contactUs,
      icon: navigationContactIcon
    },
    config.enabledSandbox && {
      listItemTextKey: Texts.NavigationContactUsTitle,
      url: routePaths.sandbox
    }
  ].filter((i) => !!i) as NavigationItems[]

export const isSandbox = (url: string) => url === routePaths.sandbox
