import { Variant } from 'src/moesia/components/SubmitButton'
import FigmaColors from 'src/figma/tokens/FigmaColors'
import Shadows from 'src/figma/tokens/Shadows'

export const getSubmitButtonActiveStyle = (variant: Variant) => {
  switch (variant) {
    case 'primary':
      return `background-color: ${FigmaColors.blueDark};`

    case 'secondary':
      return `background-color: ${FigmaColors.bhBlueLight};`

    case 'tertiary':
      return `background-color: ${FigmaColors.tertiaryButtonBackgroundColor};`

    default:
      return `background-color: ${FigmaColors.blueDark};`
  }
}

export const getSubmitButtonHoverAndFocusStyle = (variant: Variant) => {
  switch (variant) {
    case 'primary':
      return `
            background-color: ${FigmaColors.blueDark};
         `
    case 'secondary':
      return `
            background-color: ${FigmaColors.bhBlueLight};
        `
    case 'tertiary':
      return `
            background-color: ${FigmaColors.tertiaryButtonBackgroundColor};
            box-shadow: ${Shadows.baseHeavy};
        `
    default:
      return `
    background-color: ${FigmaColors.blueDark};
 `
  }
}

export const getSubmitButtonDisabledStyle = (variant: Variant) => {
  switch (variant) {
    case 'primary':
      return `background-color: ${FigmaColors.grayLight};`

    case 'secondary':
      return `background-color: ${FigmaColors.grayLight};`

    case 'tertiary':
      return `background-color: transparent;`

    default:
      return `background-color: ${FigmaColors.grayLight};`
  }
}
