import React from 'react'
import StyledBox from 'src/figma/components/StyledBox'
import useIsMobile from 'src/moesia/hooks/useIsMobile'
import MobileNavigation from 'src/navigation/MobileNavigation'
import styled from 'styled-components'
import Navigation from '../navigation/Navigation'
import DesktopFooter from './DesktopFooter'
import GDPRModal from './GDPRModal'
import MobileFooter from './MobileFooter'

interface ITervelLayout {
  children: React.ReactNode
}

const TervelLayout: React.FC<ITervelLayout> = ({ children }) => {
  const isMobile = useIsMobile()
  return (
    <>
      {!isMobile && <Navigation />}
      {isMobile && <MobileNavigation />}
      <ContentWrapper fullWidth>{children}</ContentWrapper>
      {!isMobile && <DesktopFooter />}
      {isMobile && <MobileFooter />}
      <GDPRModal />
    </>
  )
}

const ContentWrapper = styled(StyledBox)`
  overflow: hidden;
  position: relative;
`

export default TervelLayout
