// THIS FILE IS AUTO-GENERATED BY FIGMAGIC. DO NOT MAKE EDITS IN THIS FILE! CHANGES WILL GET OVER-WRITTEN BY ANY FURTHER PROCESSING.

import fontFamilies from 'src/figma/tokens/fontFamilies'
import fontWeights from 'src/figma/tokens/fontWeights'
import lineHeights from 'src/figma/tokens/lineHeights'
import Spacings from 'src/figma/tokens/Spacings'
import colors from 'src/figma/tokens/colors'
import Shadows from 'src/figma/tokens/Shadows'

const ButtonPrimaryDefaultCss = `
  color: rgba(0; 51; 102; 1);
  font-size: 20;
  font-family: ${fontFamilies.roboto};
  font-weight: ${fontWeights.medium};
  line-height: ${lineHeights.xs};
  text-align: left;
  width: 100%;
  box-sizing: border-box;
  border: 0;
  border-style: solid;
  padding-top: 1.0625rem;
  padding-bottom: 1.125rem;
  padding-left: 2.3125rem;
  padding-right: 2.3125rem;
  height: 58;
  background-color: ${colors.rectangle1};
  background-color: ${colors.white};
  border-color: ${colors.rectangle1};
  border-color: ${colors.white};
  border-radius: 50px;
  box-shadow: ${Shadows.illustrationMedium};
`

export default ButtonPrimaryDefaultCss
