import { RenderArrowProps } from '@itseasy21/react-elastic-carousel'
import { Button } from '@material-ui/core'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import styled from 'styled-components'
import FigmaColors from 'src/figma/tokens/FigmaColors'
import Spacings from 'src/figma/tokens/Spacings'
import StyledBox from '../moesia/components/StyledBox'

enum CarouselDirection {
  PREV = 'PREV',
  NEXT = 'NEXT'
}

const CarouselScrollArrowComponent = ({ type, onClick, isEdge }: RenderArrowProps) => {
  const pointer = type === CarouselDirection.PREV ? <ArrowBackIosNewIcon /> : <ArrowForwardIosIcon />
  return (
    <StyledBox align="center" justify="center" left right spacing={Spacings.minimum}>
      <StyledButton onClick={onClick} disabled={isEdge}>
        {pointer}
      </StyledButton>
    </StyledBox>
  )
}

export default CarouselScrollArrowComponent

const StyledButton = styled(Button)`
  &&& {
    background-color: ${FigmaColors.white};
    border-radius: 50%;

    &.MuiButton-root {
      min-width: 30px;
    }

    &:hover {
      transition: all 0.3s ease;
      background-color: ${FigmaColors.stone};
    }
  }
`
