import { makeStyles } from '@material-ui/core'
import React from 'react'
import styled from 'styled-components'
import FigmaImage from '../figma/components/FigmaImage'
import Spacings from 'src/figma/tokens/Spacings'
import TransformLetters from '../helpers/TransformLetters'
import useImageVariant from '../hooks/useImageVariant'
import { FigmaImageType } from '../interfaces/FigmaTypes'
import useIsMobile from 'src/moesia/hooks/useIsMobile'

type Props = {
  imageKey: FigmaImageType
  fullWidth?: boolean
}

const HomeSloganComponent: React.FC<Props> = ({ imageKey, fullWidth }) => {
  const variantStyles = useImageVariant(imageKey)
  const classes = useStyles()
  const isMobile = useIsMobile()

  if (!variantStyles) return null
  return (
    <Wrapper
      style={{
        maxWidth: fullWidth ? '100%' : variantStyles.width
      }}
    >
      {isMobile ? (
        <div>
          <FigmaImage imageKey={imageKey} imageStyle={classes.mobileImage} />
          <div className={`${classes.text} ${classes.textMobile}`}>
            <TransformLetters input={'Simple and'} />
            <TransformLetters input={'Elegant'} />
            <TransformLetters input={'Solutions'} />
          </div>
        </div>
      ) : (
        <div>
          <FigmaImage imageStyle={classes.imageStyle} imageKey={imageKey} />
          <div className={classes.text}>
            <TransformLetters input={'Simple and Elegant Solutions'} />
          </div>
        </div>
      )}
    </Wrapper>
  )
}
const Wrapper = styled.div`
  display: flex;
  position: relative;
  max-height: 360px;
  @media (max-width: 900px) {
    min-height: 630px;
  }
`
export default HomeSloganComponent

const useStyles = makeStyles(() => ({
  text: {
    width: '100%',
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',

    '&& span': {
      fontFamily: 'Roboto !important'
    }
  },
  textMobile: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '100%',
    gap: Spacings.min,
    paddingBottom: Spacings.medium,

    '&& span': {
      lineHeight: 'normal !important'
    }
  },
  mobileImage: {
    height: '100%',
    objectFit: 'cover'
  },
  imageStyle: {
    height: '100%',
    width: '100vw'
  }
}))
