/* eslint-disable */
// prettier-ignore
// THIS FILE IS AUTO-GENERATED BY EMU. DO NOT MAKE EDITS IN THIS FILE! CHANGES WILL GET OVER-WRITTEN BY ANY FURTHER PROCESSING.
export default {
  "negative": -1,
  "regular": 0,
  "minimum": 1,
  "high": 100,
  "modal": 1000,
  "tooltip": 1200,
  "heaven": 9999
}