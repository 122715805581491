import { memo } from 'react'
import bhDots from 'src/figma/images/bhDots'
import styled from 'styled-components'
import StyledBox from '../moesia/components/StyledBox'

import SVGContainer from '../moesia/components/SVGContainer'

const HomeSliderHorizontalDotsComponent = () => (
  <WrapperBox alignSelf="center">
    <StyledSVGContainer SVG={bhDots} />
  </WrapperBox>
)

export default memo(HomeSliderHorizontalDotsComponent)

const WrapperBox = styled(StyledBox)`
  transform: rotate(90deg);
`

const StyledSVGContainer = styled(SVGContainer)`
  svg {
    width: 27px;
    height: 122px;
  }
`
