const routePaths = {
  entry: '/',
  company: '/company',
  solutions: '/solutions',
  portfolio: '/portfolio',
  joinUs: '/join-us',
  contactUs: '/contact-us',
  getInTouch: '/get-in-touch',
  sandbox: '/sandbox',
  error404Page: '/error404',
  underConstructionPage: '/underConstruction',
  somethingWentWrong: '/somethingWentWrong',
  redirectedFromJobsBg: '/redirected-from-jobs-bg',
  redirectedFromDevBg: '/redirected-from-dev-bg',
  redirectedFromRecommendation: '/redirected-from-recommendation'
}

export default routePaths
