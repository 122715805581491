import { Texts } from '../figma/helpers/TextRepository'
import { TextKeyListType } from './TextKeyListType'

export const developerList = (): TextKeyListType[] => [
  { textKey: Texts.Hire1 },
  { textKey: Texts.Hire2 },
  { textKey: Texts.Hire3 },
  { textKey: Texts.Hire4 },
  { textKey: Texts.Hire5 },
  { textKey: Texts.Hire6 },
  { textKey: Texts.Hire7 },
  { textKey: Texts.Hire8 }
]

export const projectBuildList = (): TextKeyListType[] => [
  { textKey: Texts.WeCan1 },
  { textKey: Texts.WeCan2 },
  { textKey: Texts.WeCan3 },
  { textKey: Texts.WeCan4 },
  { textKey: Texts.WeCan5 },
  { textKey: Texts.WeCan6 },
  { textKey: Texts.WeCan7 },
  { textKey: Texts.WeCan8 }
]
