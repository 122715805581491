import * as React from 'react'

import ButtonPrimaryActionStyled from './ButtonPrimaryActionStyled'

type ButtonPrimaryActionProps = any

const ButtonPrimaryAction: React.FC<ButtonPrimaryActionProps> = (props) => (
  <ButtonPrimaryActionStyled {...props}>{props.children ? props.children : 'Company'}</ButtonPrimaryActionStyled>
)

export default ButtonPrimaryAction
