import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import AnimatedRoutes from './components/AnimatedRoutes'

const Router: React.FC = () => (
  <BrowserRouter>
    <AnimatedRoutes />
  </BrowserRouter>
)

export default React.memo(Router)
