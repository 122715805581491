import { makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import React from 'react'
import Spacings from 'src/figma/tokens/Spacings'
import TextContainer from 'src/moesia/components/TextContainer'
import styled from 'styled-components'
import { Texts } from '../figma/helpers/TextRepository'
import { TransformLetterType } from '../interfaces/TransformLetterTypes'
import createKeyIndex from './createKeyIndex'

type Props = {
  input: string
}

const TransformLetters: React.FC<Props> = ({ input }) => {
  const classes = useStyles()
  const inputText = input.split(' ')
  const array: TransformLetterType[] = []

  inputText.map((word) => {
    let obj: TransformLetterType = {
      textKey: Texts.genericCapitalSlogan,
      text: '',
      padding: false
    }
    const currentWord = word
    if (currentWord[0] === currentWord[0].toUpperCase()) {
      obj = { text: currentWord[0], textKey: Texts.genericCapitalSlogan, padding: false }
      array.push(obj)
      const smallLettersWord = currentWord.slice(1)
      obj = { text: smallLettersWord, textKey: Texts.Slogan, padding: true }
      array.push(obj)
    } else {
      obj = { text: currentWord, textKey: Texts.Slogan, padding: true }
      array.push(obj)
    }
    return array
  })
  return (
    <InlineText>
      {array.map(({ textKey, text, padding }, index) => {
        const verifyLast = index < array.length - 1
        return (
          <TextContainer
            className={clsx(verifyLast && padding && classes.padding)}
            key={createKeyIndex(index, 'transformedLetters')}
            textKey={textKey}
            text={text}
          />
        )
      })}
    </InlineText>
  )
}

const useStyles = makeStyles({
  padding: {
    paddingRight: Spacings.small
  }
})

const InlineText = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: baseline;
  width: 100%;
  flex-wrap: no-wrap;
`

export default TransformLetters
