import { TERVEL_PEOPLE_IMAGE_WIDTH } from 'src/moesia/StrongHardCodedSizes'

export const determineTransform = (slideImageLeft: boolean, isInViewPort: boolean | null) => {
  switch (true) {
    case isInViewPort && slideImageLeft:
      return 'translateX(-190%)'
    case isInViewPort && !slideImageLeft:
      return 'translateX(190%)'
    case !isInViewPort && slideImageLeft:
      return `translateX(-${TERVEL_PEOPLE_IMAGE_WIDTH / 2}px)`
    case !isInViewPort && !slideImageLeft:
      return `translateX(${TERVEL_PEOPLE_IMAGE_WIDTH / 2}px)`
    default:
      return ''
  }
}
