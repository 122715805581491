import { memo, useState } from 'react'
import StyledBox from '../moesia/components/StyledBox'
import createKeyIndex from '../helpers/createKeyIndex'
import useAppState from '../hooks/useAppState'
import Spacings from 'src/figma/tokens/Spacings'
import ImageContainer from '../moesia/components/ImageContainer'
import TextContainer from '../moesia/components/TextContainer'
import styled from 'styled-components'
import { Language } from '../interfaces/LanguageType'
import fontWeights from 'src/figma/tokens/fontWeights'
import Shadows from 'src/figma/tokens/Shadows'
import BorderRadius from 'src/figma/tokens/BorderRadius'
import DevPopperExperimental from '../moesia/components/DevPopperExperimental'
import { languages } from 'src/helpers/LanguagesHelper'

type Props = {
  popperBoxBackgroundColor?: string
  buttonColor?: string
}

const LanguageBar = ({ popperBoxBackgroundColor, buttonColor }: Props) => {
  const { state, setState } = useAppState()
  const [visible, setVisible] = useState(false)

  const selectedLanguage = languages.find((lang) => lang.value === state.language) || languages[0]

  const onSelectLanguage = (language: Language) => {
    setState({ ...state, language })
    setVisible(false)
  }

  return (
    <StyledBox align="center">
      <DevPopperExperimental
        buttonTextKey={selectedLanguage.text}
        buttonImg={selectedLanguage.currentLangImg}
        buttonColor={buttonColor}
        visible={visible}
        onOpen={() => setVisible(true)}
        handleClose={() => setVisible(false)}
        popperBoxBackgroundColor={popperBoxBackgroundColor}
      >
        {languages.map(({ value, text, currentLangSmallImg }, idx) => (
          <LanguageBarWrapper fullPadding={Spacings.minimum} onClick={() => onSelectLanguage(value)} key={createKeyIndex(idx, 'language-bar')}>
            <StyledBox fullWidth direction="row" align="center">
              <StyledInnerMenu right={Spacings.small}>
                <StyledImageContainer imageKey={currentLangSmallImg} />
              </StyledInnerMenu>
              <StyledTextContainer isSelected={value === selectedLanguage.value} textKey={text} />
            </StyledBox>
          </LanguageBarWrapper>
        ))}
      </DevPopperExperimental>
    </StyledBox>
  )
}

export default memo(LanguageBar)

const LanguageBarWrapper = styled(StyledBox)`
  transition: all 0.3s ease-in-out;
  &:hover {
    transform: scale(1.05);
  }
`

const StyledImageContainer = styled(ImageContainer)`
  border-radius: ${BorderRadius.rounded};
  box-shadow: ${Shadows.baseHeavy};
`

const StyledTextContainer = styled(TextContainer)<{ isSelected: boolean }>`
  span {
    font-size: ${({ isSelected }) => isSelected && `${Spacings.small} !important`};
    font-weight: ${({ isSelected }) => isSelected && `${fontWeights.bold} !important`};
  }

  &:hover {
    cursor: pointer;
  }
`

const StyledInnerMenu = styled(StyledBox)`
  cursor: pointer;
`
