import React, { memo } from 'react'
import styled from 'styled-components'
import StyledBox from '../moesia/components/StyledBox'
import DesktopNavigation from './DesktopNavigation'
import useIsMobile from 'src/moesia/hooks/useIsMobile'
import ZIndex from 'src/figma/tokens/ZIndex'

const Navigation: React.FC = () => {
  const isMobile = useIsMobile()

  return (
    <Wrapper fullWidth position={isMobile && 'absolute'}>
      <DesktopNavigation />
    </Wrapper>
  )
}

export default memo(Navigation)

const Wrapper = styled(StyledBox)<{ $isMobile: boolean }>`
  top: ${({ $isMobile }) => $isMobile && '190px'};
  left: ${({ $isMobile }) => $isMobile && '50%'};
  transform: ${({ $isMobile }) => $isMobile && 'translate(-50%, -50%)'};
  z-index: ${({ $isMobile }) => $isMobile && ZIndex.minimum};
  display: flex;
`
