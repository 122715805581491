/* eslint-disable */
// prettier-ignore
// THIS FILE IS AUTO-GENERATED BY EMU. DO NOT MAKE EDITS IN THIS FILE! CHANGES WILL GET OVER-WRITTEN BY ANY FURTHER PROCESSING.
export default {
  "baseGrey": "rgb(120, 120, 122)",
  "baseBlack": "rgb(27, 28, 31)",
  "stone": "rgb(216, 216, 218)",
  "cloud": "rgb(251, 251, 252)",
  "baseGarden": "rgb(42, 204, 136)",
  "baseGreen": "rgb(105, 224, 174)",
  "baseWhite": "rgb(255, 255, 255)",
  "baseCritical": "rgb(216, 44, 13)",
  "baseWarning": "rgb(255, 196, 83)",
  "baseSuccess": "rgb(0, 128, 96)",
  "backgroundGazelle": "rgb(246, 246, 247)",
  "backgroundAntiloop": "rgb(251, 251, 252)",
  "backgroundBlurred": "rgb(195, 195, 195)",
  "surfaceOnWhite": "rgb(246, 246, 247)",
  "textDefault": "rgb(32, 34, 35)",
  "textSubdued": "rgb(120, 120, 122)",
  "textDisabled": "rgb(216, 216, 218)",
  "textLink": "rgb(13, 130, 81)",
  "textLinkHover": "rgb(82, 131, 111)",
  "iconDefault": "rgb(27, 28, 31)",
  "gray": "rgb(130, 130, 130)",
  "iconCritical": "rgb(215, 44, 13)",
  "iconWarning": "rgb(185, 137, 0)",
  "iconSuccess": "rgb(0, 127, 95)",
  "decorativePowder": "rgb(255, 242, 238)",
  "decorativeLilac": "rgb(241, 234, 254)",
  "decorativeMint": "rgb(216, 255, 239)",
  "decorativeViolett": "rgb(90, 52, 161)",
  "decorativeTiger": "rgb(255, 128, 87)",
  "focusedDefault": "rgb(42, 204, 136)",
  "decorativeSurfaceFive": "rgb(253, 201, 208)",
  "decorativeSurfaceFour": "rgb(145, 224, 214)",
  "decorativeSurfaceThree": "rgb(146, 230, 181)",
  "decorativeSurfaceTwo": "rgb(255, 196, 176)",
  "decorativeSurfaceOne": "rgb(255, 201, 107)",
  "white": "rgb(255, 255, 255)",
  "green3": "rgb(216, 255, 239)",
  "green2": "rgb(105, 224, 174)",
  "green1": "rgb(42, 204, 136)",
  "red": "rgb(167, 38, 8)",
  "yellow": "rgb(242, 201, 76)",
  "lilac": "rgb(242, 236, 254)",
  "violett": "rgb(90, 52, 161)",
  "powder": "rgb(255, 242, 238)",
  "tiger": "rgb(255, 128, 87)",
  "gray2": "rgb(216, 216, 218)",
  "black": "rgb(27, 28, 31)",
  "gray1": "rgb(120, 120, 122)",
  "mintLinear": "linear-gradient(135deg, rgb(236, 255, 247) 0%, rgb(255, 255, 255) 100%)",
  "greenHover": "rgb(82, 131, 111)",
  "greenLink": "rgb(13, 130, 81)",
  "gray3": "rgb(251, 251, 252)",
  "grayLight": "rgb(211, 210, 208)",
  "bhBlueLight": "rgb(165, 201, 215)",
  "bhBlue": "rgb(0, 103, 142)",
  "blueDark": "rgb(0, 51, 102)",
  "blackBlue": "rgb(0, 20, 43)",
  "blueHover": "rgb(210, 228, 235)",
  "orange": "rgb(238, 127, 0)",
  "bhBlueDark": "rgb(0, 84, 116)",
  "green": "rgb(178, 213, 165)",
  "grayDark": "rgb(77, 76, 76)"
}