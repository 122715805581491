import { Texts } from '../figma/helpers/TextRepository'
import routePaths from '../config/RoutePaths'
import { TextType } from './FigmaTypes'
import nestedRoutePaths from '../config/NestedRoutePaths'

export interface FooterItems {
  listItemTextKey: TextType
  url: string
  subMenuSections?: {
    sectionTitle: TextType
    subMenuUrl: string
    positionX: number
    positionY: number
  }[]
}

//SUPPORT CONFIGURATION AND FEATURE FLAGS
export const getFooterItems = (): FooterItems[] =>
  [
    {
      listItemTextKey: Texts.FooterCompany,
      url: routePaths.company,
      subMenuSections: [
        {
          sectionTitle: Texts.FooterAboutUs,
          subMenuUrl: nestedRoutePaths.aboutUs,
          positionX: 0,
          positionY: 0
        },
        { sectionTitle: Texts.FooterLidership, subMenuUrl: nestedRoutePaths.team, positionX: 0, positionY: 900 },
        { sectionTitle: Texts.FooterNews, subMenuUrl: nestedRoutePaths.news, positionX: 0, positionY: 1800 }
      ]
    },
    {
      listItemTextKey: Texts.FooterSolutions,
      url: routePaths.solutions,
      subMenuSections: [
        {
          sectionTitle: Texts.FooterHowCanWe,
          subMenuUrl: nestedRoutePaths.howCanWeHelp,
          positionX: 0,
          positionY: 0
        },
        { sectionTitle: Texts.FooterHire, subMenuUrl: nestedRoutePaths.hireADeveloper, positionX: 0, positionY: 980 },
        { sectionTitle: Texts.FooterWecan, subMenuUrl: nestedRoutePaths.buildProject, positionX: 0, positionY: 1700 }
      ]
    },
    {
      listItemTextKey: Texts.FooterContactUs,
      url: routePaths.contactUs,
      subMenuSections: [{ sectionTitle: Texts.DevHarbourOfficeEmail }, { sectionTitle: Texts.DevHarbourTel }, { sectionTitle: Texts.DevHarbourAddress }]
    }
  ].filter((i) => !!i) as never
