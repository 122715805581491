import React from 'react'
import Router from './Router'
import { HelmetProvider } from 'react-helmet-async'
import { Context, useAppState } from './hooks/useAppState'
import HelmetMaterialUIIcons from './helpers/HelmetMaterialUIIcons'
import useGA4Tracker from './hooks/useGA4Tracker'
import SomethingWentWrongComponent from './components/SomethingWentWrongComponent'
import * as Sentry from '@sentry/react'

const App: React.FC = () => {
  const { state, setState } = useAppState()
  const { hasCookies } = state
  useGA4Tracker(hasCookies)

  return (
    <Sentry.ErrorBoundary fallback={<SomethingWentWrongComponent />}>
      <HelmetProvider>
        <Context.Provider value={{ state, setState }}>
          <HelmetMaterialUIIcons />
          <Router />
        </Context.Provider>
      </HelmetProvider>
    </Sentry.ErrorBoundary>
  )
}
export default App
