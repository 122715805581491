import { MenuItem } from '@material-ui/core'
import FigmaColors from 'src/figma/tokens/FigmaColors'
import StyledLink from './StyledLink'
import TextContainer from '../moesia/components/TextContainer'
import createKeyIndex from '../helpers/createKeyIndex'
import styled from 'styled-components'
import { Texts } from '../figma/helpers/TextRepository'
import { TextType } from '../interfaces/FigmaTypes'
import { memo } from 'react'
import StyledBox from 'src/figma/components/StyledBox'
import { DEV_HARBOUR_GOOGLE_MAPS_LINK } from 'src/moesia/Constants'
import { useNavigate } from 'react-router-dom'

type Props = {
  subMenuSections: { sectionTitle: TextType; subMenuUrl: string; positionX: number; positionY: number }[] | undefined
}

const FooterSubSection = ({ subMenuSections }: Props) => {
  const navigate = useNavigate()

  const handleNavigate = (url: string, positionX: number, positionY: number) => {
    navigate(url)
    scrollTo({ left: positionX, top: positionY, behavior: 'smooth' })
  }

  return (
    <FooterSubSectionWrapper>
      {!!subMenuSections &&
        subMenuSections.map(({ sectionTitle, subMenuUrl, positionX, positionY }, index: number) => (
          <MenuItem key={createKeyIndex(index, 'footer-sections')}>
            <SubMenuItemsWrapper>
              {sectionTitle === Texts.DevHarbourOfficeEmail && (
                <StyledLink href={'mailto:office@dev-harbour.com'}>
                  <StyledTextContainer textKey={Texts.DevHarbourOfficeEmail} />
                </StyledLink>
              )}
              {sectionTitle === Texts.DevHarbourTel && (
                <StyledLink href="tel:+359877892728">
                  <StyledTextContainer textKey={Texts.DevHarbourTel} />
                </StyledLink>
              )}
              {sectionTitle !== Texts.DevHarbourTel && sectionTitle !== Texts.DevHarbourOfficeEmail && sectionTitle !== Texts.DevHarbourAddress && (
                <StyledBox onClick={() => handleNavigate(!!subMenuUrl ? subMenuUrl : '', positionX, positionY)}>
                  <StyledTextContainer textKey={sectionTitle} />
                </StyledBox>
              )}
              {sectionTitle === Texts.DevHarbourAddress && (
                <StyledLink openInNewTab href={DEV_HARBOUR_GOOGLE_MAPS_LINK}>
                  <StyledTextContainer textKey={Texts.DevHarbourAddress} />
                </StyledLink>
              )}
            </SubMenuItemsWrapper>
          </MenuItem>
        ))}
    </FooterSubSectionWrapper>
  )
}

export default memo(FooterSubSection)

const SubMenuItemsWrapper = styled(StyledBox)`
  white-space: normal;
`

const StyledTextContainer = styled(TextContainer)`
  text-align: left;
  span {
    line-height: 1.5 !important;
    font-size: 20px !important;
  }
  &:hover {
    && span {
      color: ${FigmaColors.green} !important;
    }
  }
`

const FooterSubSectionWrapper = styled(StyledBox)`
  li {
    min-height: 24px;
  }
`
