/* eslint-disable no-magic-numbers */
import { cloneDeep } from 'lodash'

export const getShuffledArray = <T>(array: T[]) => {
  const arrCopy = cloneDeep(array)
  return arrCopy.sort(() => Math.random() - 0.5)
}

export const generateUUID = () =>
  'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      // eslint-disable-next-line eqeqeq
      v = c == 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })

export const phoneRegex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/
