/* eslint-disable */
// prettier-ignore
// THIS FILE IS AUTO-GENERATED BY EMU. DO NOT MAKE EDITS IN THIS FILE! CHANGES WILL GET OVER-WRITTEN BY ANY FURTHER PROCESSING.
export default {
  "smallest": "2px",
  "minimum": "4px",
  "min": "8px",
  "tiny": "12px",
  "small": "16px",
  "medium": "24px",
  "large": "32px",
  "big": "48px",
  "huge": "64px",
  "max": "96px"
}