import React, { useEffect, memo } from 'react'
import { Helmet } from 'react-helmet-async'
import { Route, Routes, useLocation } from 'react-router-dom'
import RouterPublicRoutes from '../RouterPublicRoutes'
import Error404Component from './Error404Component'
import { AnimatePresence } from 'framer-motion'
import qs from 'query-string'
import { PageEntryType, WebsiteEntryType } from 'src/enums/GoogleAnalyticsEnums'
import { sendGAEvent } from 'src/helpers/GoogleAnalyticsHelper'

const AnimatedRoutes = () => {
  const location = useLocation()

  useEffect(() => {
    const queryParams = qs.parse(window.location.search)
    if (!queryParams?.typeEntry) return
    const gaEvent = PageEntryType[queryParams.typeEntry as WebsiteEntryType]

    sendGAEvent(gaEvent)
  }, [])

  return (
    <AnimatePresence>
      <Routes location={location} key={location.pathname}>
        {RouterPublicRoutes.map(({ component: Component, path, title }) => (
          <Route
            path={path}
            key={path}
            element={
              <>
                <Component />
                <Helmet title={title} />
              </>
            }
          />
        ))}
        <Route path="*" element={<Error404Component />} />
      </Routes>
    </AnimatePresence>
  )
}

export default memo(AnimatedRoutes)
