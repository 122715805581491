export const COMPANY = 'Company'
export const SOLUTIONS = 'Solutions'
export const PORTFOLIO = 'Portfolio'
export const JOIN_US = 'Join us'
export const GET_IN_TOUCH = 'Get in touch'
export const REGISTER = 'Register'
export const HOME = 'Home'
export const SANDBOX = 'Sandbox'
export const CONTACT_US = 'Contact us'
export const ERROR_404 = 'Error 404'
export const UNDER_CONSTRUCTION = 'Under construction'
