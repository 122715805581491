import { FC, memo, SVGProps } from 'react'
import TextContainer from 'src/moesia/components/TextContainer'
import styled from 'styled-components'
import { Texts } from '../figma/helpers/TextRepository'
import BorderRadius from '../figma/tokens/BorderRadius'
import FigmaColors from '../figma/tokens/FigmaColors'
import Spacings from '../figma/tokens/Spacings'
import ZIndex from '../figma/tokens/ZIndex'
import StyledBox from '../moesia/components/StyledBox'
import SVGContainer from '../moesia/components/SVGContainer'
import { TextType } from '../moesia/interfaces/FigmaTypes'
import { TERVEL_CLIENT_CARD_HEIGHT, TERVEL_CLIENT_CARD_WIDTH } from '../moesia/StrongHardCodedSizes'
import { CardWrapper } from './StyleComponents'
import StyledLink from './StyledLink'

type Props = {
  clientLogo: FC<SVGProps<SVGSVGElement>>
  centerText: TextType
  representativeName?: TextType
  clientWebsite?: string
}

const ClientCardDesktop = ({ clientLogo, centerText, representativeName, clientWebsite }: Props) => (
  <CardWrapper
    width={TERVEL_CLIENT_CARD_WIDTH}
    height={TERVEL_CLIENT_CARD_HEIGHT}
    backgroundColor={FigmaColors.white}
    transform={clientWebsite && 'rotateY(180deg)'}
    position={representativeName && 'absolute'}
    fullPadding
    spacing={Spacings.medium}
    borderRadius={`${BorderRadius.soft} ${BorderRadius.soft} ${BorderRadius.circle} ${BorderRadius.soft}`}
    justify="space-between"
  >
    <StyledBox alignSelf="flex-end" bottom spacing={Spacings.medium}>
      <SVGContainer SVG={clientLogo} />
    </StyledBox>
    <StyledBox fullWidth alignText="left" left={Spacings.tiny} right={Spacings.tiny} bottom={Spacings.medium}>
      <TextContainer textKey={centerText} />
    </StyledBox>
    {clientWebsite && (
      <ViewMoreLinkWrapper top={Spacings.medium}>
        <StyledLink alignSelf="flex-start" href={clientWebsite} openInNewTab>
          <TextContainer textKey={Texts.ViewMore} />
        </StyledLink>
      </ViewMoreLinkWrapper>
    )}
    {representativeName && (
      <StyledBox alignSelf="flex-start" top={Spacings.medium}>
        <TextContainer textKey={representativeName} />
      </StyledBox>
    )}
  </CardWrapper>
)

export default memo(ClientCardDesktop)

const ViewMoreLinkWrapper = styled(StyledBox)`
  z-index: ${ZIndex.high};
`
