import { memo, useState } from 'react'
import TextContainer from 'src/moesia/components/TextContainer'
import { Texts } from '../figma/helpers/TextRepository'
import BorderRadius from '../figma/tokens/BorderRadius'
import FigmaColors from '../figma/tokens/FigmaColors'
import Spacings from '../figma/tokens/Spacings'
import ZIndex from '../figma/tokens/ZIndex'
import StyledBox from '../moesia/components/StyledBox'
import SVGContainer from '../moesia/components/SVGContainer'
import { TERVEL_CLIENT_CARD_HEIGHT } from '../moesia/StrongHardCodedSizes'
import StyledLink from './StyledLink'
import { ClientTypes } from '../interfaces/ClientTypes'
import { CardWrapper } from './StyleComponents'
import styled from 'styled-components'

type Props = {
  clientCard: ClientTypes
}

const ClientCardTablet = ({ clientCard }: Props) => {
  const { clientLogo, clientTestimonyText, clientRepresentativeName, clientDescription, clientWebsite } = clientCard
  const [isRotated, setIsRotated] = useState(false)

  return (
    <CardWrapper
      width="80%"
      height={TERVEL_CLIENT_CARD_HEIGHT}
      backgroundColor={FigmaColors.white}
      fullPadding
      spacing={Spacings.medium}
      borderRadius={`${BorderRadius.soft} ${BorderRadius.soft} ${BorderRadius.circle} ${BorderRadius.soft}`}
      justify="space-between"
      onClick={() => setIsRotated(!isRotated)}
    >
      <StyledBox alignSelf="flex-end" bottom spacing={Spacings.medium}>
        <SVGContainer SVG={clientLogo} />
      </StyledBox>
      <CardMiddleTextContent fullWidth alignText="left" left={Spacings.tiny} right={Spacings.tiny} bottom={Spacings.medium}>
        <TextContainer textKey={isRotated ? clientDescription : clientTestimonyText} />
      </CardMiddleTextContent>
      {isRotated && clientWebsite && (
        <ViewMoreLinkWrapper top={Spacings.medium}>
          <StyledLink alignSelf="flex-start" href={clientWebsite} openInNewTab>
            <TextContainer textKey={Texts.ViewMore} />
          </StyledLink>
        </ViewMoreLinkWrapper>
      )}
      {!isRotated && clientRepresentativeName && (
        <StyledBox alignSelf="flex-start" top={Spacings.medium}>
          <TextContainer textKey={clientRepresentativeName} />
        </StyledBox>
      )}
    </CardWrapper>
  )
}

export default memo(ClientCardTablet)

const CardMiddleTextContent = styled(StyledBox)`
  overflow: auto;
  /* hide the default scrollbar */
  ::-webkit-scrollbar {
    width: ${Spacings.minimum};
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${FigmaColors.bhBlue};
    border-radius: ${BorderRadius.rounded};
  }
`

const ViewMoreLinkWrapper = styled(StyledBox)`
  z-index: ${ZIndex.high};
`
