// THIS FILE IS AUTO-GENERATED BY FIGMAGIC. DO NOT MAKE EDITS IN THIS FILE! CHANGES WILL GET OVER-WRITTEN BY ANY FURTHER PROCESSING.

const colors = {
 "rectangle1": "rgba(255, 255, 255, 1)",
 "mint": "rgba(216, 255, 239, 1)",
 "green": "rgba(178, 213, 165, 1)",
 "garden": "rgba(42, 204, 136, 1)",
 "red": "rgba(167, 38, 8, 1)",
 "yellow": "rgba(242, 201, 76, 1)",
 "lilac": "rgba(241, 234, 254, 0.9)",
 "violett": "rgba(90, 52, 161, 1)",
 "powder": "rgba(255, 242, 238, 1)",
 "tiger": "rgba(255, 128, 87, 1)",
 "stoneTransparent": "rgba(216, 216, 218, 0.7)",
 "stone": "rgba(216, 216, 218, 1)",
 "black": "rgba(27, 28, 31, 1)",
 "gray": "rgba(130, 130, 130, 1)",
 "white": "rgba(255, 255, 255, 1)",
 "mintLinear": "linear-gradient(135deg, rgba(216, 255, 239, 0.5) 0%, rgba(255, 255, 255, 0) 100%)",
 "greenHover": "rgba(82, 131, 111, 1)",
 "greenLink": "rgba(13, 130, 81, 1)",
 "cloud": "rgba(251, 251, 252, 1)",
 "grayLight": "rgba(211, 210, 208, 1)",
 "bhblueLight": "rgba(165, 201, 215, 1)",
 "bhblue": "rgba(0, 103, 142, 1)",
 "blueDark": "rgba(0, 20, 43, 1)",
 "blueHover": "rgba(210, 228, 235, 1)",
 "orange": "rgba(238, 127, 0, 1)",
 "bhblueDark": "rgba(0, 84, 116, 1)",
 "grayDark": "rgba(77, 76, 76, 1)",
 "hoverColors": "rgba(0, 0, 0, 1)"
}

export default colors;