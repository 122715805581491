/* eslint-disable */
// prettier-ignore
// THIS FILE IS AUTO-GENERATED BY EMU. DO NOT MAKE EDITS IN THIS FILE! CHANGES WILL GET OVER-WRITTEN BY ANY FURTHER PROCESSING.
export default {
  "baseSoft": "0px 0px 2px rgb(221, 221, 221)",
  "baseMedium": "0px 0px 10px rgb(235, 235, 235)",
  "baseHeavy": "0px 0px 6px rgb(201, 201, 202)",
  "baseCard": "0px 2px 1px rgb(247, 247, 247)",
  "infoboxSoftBottom": "0px 2px 1px rgb(241, 241, 242)",
  "buttonGreen": "0px 0px 6px rgb(170, 235, 207)",
  "hoverMedium": "0px 8px 25px rgb(201, 201, 202)",
  "illustrationMedium": "0px 2px 8px rgb(178, 178, 178)",
  "illustrationHeavy": "-4px 20px 20px rgb(229, 229, 229)",
  "generalEagleBottomNav": "0px 0px 1px rgb(204, 204, 204)",
  "illustrationSoft": "0px 2px 4px rgb(229, 229, 229)",
  "generalContextualBar": "0px -1px 1px rgb(229, 229, 229)",
  "generalNavBar": "0px 0px 1px rgb(140, 140, 140)"
}