import React, { useEffect, useMemo } from 'react'
import HomeContainer from 'src/screens/HomeContainer'
import { sendGAEvent } from 'src/helpers/GoogleAnalyticsHelper'
import { PageEntryType, WebsiteEntryType } from 'src/enums/GoogleAnalyticsEnums'
import qs, { ParsedQuery } from 'query-string'

type GATrackTrafficProps = {
  search?: string
}

const GATrackTraffic: React.FC<GATrackTrafficProps> = ({ search }) => {
  const queryParams = useMemo(() => {
    let queryParams: ParsedQuery<string> = {}
    if (search) {
      queryParams = { ...queryParams, ...qs.parse(search) }
      return queryParams
    }
    return
  }, [search])

  useEffect(() => {
    if (queryParams !== undefined) {
      const gaEvent = PageEntryType[queryParams?.typeEntry as WebsiteEntryType]
      sendGAEvent(gaEvent)
    }
  }, [queryParams])

  return <HomeContainer />
}
export default React.memo(GATrackTraffic)
