import { Texts } from '../figma/helpers/TextRepository'
import Spacings from '../figma/tokens/Spacings'
import StyledBox from '../moesia/components/StyledBox'
import TextContainer from '../moesia/components/TextContainer'
import HomeReachUsSocialLinks from './HomeReachUsSocialLinks'

const HomeReachUsContent = () => (
  <StyledBox align="center" left={Spacings.large} right={Spacings.max}>
    <StyledBox alignText="center">
      <TextContainer textKey={Texts.YouCanReachUsTruOne} />
      <TextContainer textKey={Texts.YouCanReachUsTruTwo} />
    </StyledBox>
    <HomeReachUsSocialLinks />
    <TextContainer textKey={Texts.OtherMethodsReachUs} />
  </StyledBox>
)

export default HomeReachUsContent
