import React from 'react'
import styled from 'styled-components'
import FigmaImage from '../figma/components/FigmaImage'
import useImageVariant from '../hooks/useImageVariant'
import { FigmaImageType } from '../interfaces/FigmaTypes'

const TemporaryImageContainer: React.FC<{
  // Changed imageStyle to string so I can use clsx lib and pass the props
  containerStyle?: { [name: string]: string }
  imageStyle?: string
  imageKey: FigmaImageType
  fullWidth?: boolean
  minHeight?: boolean
  className?: string
}> = ({ containerStyle, imageKey, fullWidth, imageStyle, minHeight, className }) => {
  const variantStyles = useImageVariant(imageKey)

  if (!variantStyles) return null
  return (
    <Wrapper
      className={className}
      style={{
        ...(!!containerStyle && containerStyle),
        maxWidth: fullWidth ? '100%' : variantStyles.width,
        maxHeight: !minHeight ? variantStyles.height : '100%'
      }}
    >
      {imageKey && <FigmaImage imageKey={imageKey} imageStyle={imageStyle} />}
    </Wrapper>
  )
}
const Wrapper = styled.div``
export default TemporaryImageContainer
