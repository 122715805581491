export enum GoogleAnalyticsEvents {
  CLICK_SOLUTIONSPAGE_CONTACTUS_FIRST = 'click_solutionsPage_contactUs_first',
  CLICK_SOLUTIONSPAGE_CONTACTUS_SECOND = 'click_solutionsPage_contactUs_second',
  CLICK_HOMEPAGE_EMAIL_OFFICE = 'click_homePage_office_email',
  CLICK_HOMEPAGE_DEVHARBOUR_LINKEDIN = 'click_homePage_devHarbour_linkedIn',
  CLICK_APPLY_FOR_JOB = 'click_apply_for_job',
  CLICK_CONTACTUSFORM_GET_STARTED = 'click_contactUsForm_getStarted',
  CONTACTUSFORM_NEW_PROJECT_STEP = 'contactUsForm_newProject_step',
  CONTACTUSFORM_DEVS_NEEDED_STEP = 'contactUsForm_developersNeeded_step',
  CONTACTUSFORM_TECH_STACK_STEP = 'contactUsForm_techStack_step',
  CONTACTUSFORM_START_WHEN_STEP = 'contactUsForm_startWhen_step',
  CONTACTUSFORM_BUDGET_STEP = 'contactUsForm_budget_step',
  CLICK_CONTACTUSFORM_SEND = 'click_contactUsForm_send',
  CONTACTUSFORM_SEND_FAILED = 'contactUsForm_send_failed',
  CLICK_NEWSPAGE_OPEN_POSITIONS = 'click_newsPage_openPositions',
  CLICK_NEWSPAGE_SOCIAL_MEDIA = 'click_newsPage_socialMedia',
  CONTACTUSFORM_CONTACT_INFO_STEP = 'contactUsForm_contactInfo_step',
  JOBS_BG_VISITOR = 'jobs_bg_visitor',
  DEV_BG_VISITOR = 'dev_bg_visitor',
  RECOMMENDATION_VISITOR = 'recommendation_visitor'
}

export enum WebsiteEntryType {
  DEV_BG = 'redirected-from-dev-bg',
  JOBS_BG = 'redirected-from-jobs-bg',
  RECOMMENDATION = 'redirected-from-recommendation'
}

export const PageEntryType: { [key in WebsiteEntryType]: GoogleAnalyticsEvents } = {
  [WebsiteEntryType.JOBS_BG]: GoogleAnalyticsEvents.JOBS_BG_VISITOR,
  [WebsiteEntryType.DEV_BG]: GoogleAnalyticsEvents.DEV_BG_VISITOR,
  [WebsiteEntryType.RECOMMENDATION]: GoogleAnalyticsEvents.RECOMMENDATION_VISITOR
}
