import React from 'react'
import { useNavigate } from 'react-router-dom'
import routePaths from 'src/config/RoutePaths'
import styled from 'styled-components'
import StyledBox from '../moesia/components/StyledBox'
import devHarbourLogo from '../figma/images/devHarbourLogo'
import SVGContainer from '../moesia/components/SVGContainer'
import { REGULAR_TRANSITION, REGULAR_TRANSFORM } from 'src/moesia/Constants'

type Props = {
  elementStyles?: string
  className?: string
  onClick?: () => void
}

const DevHarbourLogo: React.FC<Props> = ({ className, elementStyles, onClick }) => {
  const svgStyle = { height: '100%' }
  const navigate = useNavigate()
  const handleOnClick = () => {
    !!onClick && onClick()
    navigate(routePaths.entry)
  }
  return (
    <LogoWrapper onClick={handleOnClick} className={className} style={{ backgroundColor: 'transparent' }} align="center">
      <StyledSVGLogo svgStyles={svgStyle} className={elementStyles} SVG={devHarbourLogo} />
    </LogoWrapper>
  )
}

export default React.memo(DevHarbourLogo)

const StyledSVGLogo = styled(SVGContainer)`
  transition: ${REGULAR_TRANSITION};
  &:hover {
    transform: ${REGULAR_TRANSFORM};
  }
`
const LogoWrapper = styled(StyledBox)`
  background-color: transparent;
  cursor: pointer;

  &:active {
  }
`
