import { SVGProps } from 'react'
import facebook from 'src/figma/images/facebook'
import linkedin from 'src/figma/images/linkedin'
import { DEV_HARBOUR_FACEBOOK, DEV_HARBOUR_LINKEDIN } from 'src/moesia/Constants'

export interface FooterLinksItems {
  svg: (props: SVGProps<SVGSVGElement>) => JSX.Element
  url: string
}

export const getFooterLinksItems = (): FooterLinksItems[] => [
  {
    svg: facebook,
    url: DEV_HARBOUR_FACEBOOK
  },
  {
    svg: linkedin,
    url: DEV_HARBOUR_LINKEDIN
  }
]
