/* eslint-disable */
// prettier-ignore
// THIS FILE IS AUTO-GENERATED BY EMU. DO NOT MAKE EDITS IN THIS FILE! CHANGES WILL GET OVER-WRITTEN BY ANY FURTHER PROCESSING.
export default {
  "xxs": "360",
  "xs": "480",
  "sm": "640",
  "md": "768",
  "lg": "1024",
  "xl": "1280",
  "xxl": "1536"
}