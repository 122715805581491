import { memo, useState } from 'react'
import StyledBox from '../moesia/components/StyledBox'
import ClientCardMobileComponent from './ClientCardMobileComponent'
import { ClientTypes } from '../interfaces/ClientTypes'

type Props = {
  clientCard: ClientTypes
}

const ClientCardMobile = ({ clientCard }: Props) => {
  const { clientLogo, clientTestimonyText, clientDescription, clientRepresentativeName, clientWebsite } = clientCard

  const [isRotated, setIsRotated] = useState(false)

  return (
    <StyledBox fullWidth align="center">
      {!isRotated && (
        <ClientCardMobileComponent
          onClick={() => setIsRotated(!isRotated)}
          clientLogo={clientLogo}
          centerText={clientTestimonyText}
          representativeName={clientRepresentativeName}
        />
      )}
      {isRotated && (
        <ClientCardMobileComponent
          onClick={() => setIsRotated(!isRotated)}
          clientLogo={clientLogo}
          centerText={clientDescription}
          clientWebsite={clientWebsite}
        />
      )}
    </StyledBox>
  )
}

export default memo(ClientCardMobile)
