import React from 'react'
import * as Images from '../../figma/helpers/Images'
import useImageVariant from '../hooks/useImageVariant'
import { FigmaImageType } from '../interfaces/FigmaTypes'

const FigmaImage: React.FC<{
  imageKey: FigmaImageType
  imageStyle?: string
}> = ({ imageKey, imageStyle }) => {
  const variant = useImageVariant(imageKey)
  const component = (Images as any)[imageKey.name]

  if (!component) return null
  if (!variant) return null

  const style = imageStyle
  const { width, height } = variant

  if (imageKey.format === 'svg')
    return React.createElement(component, {
      style,
      alt: imageKey.name,
      width,
      height
    })

  return <img className={imageStyle} src={component} alt={imageKey.name} width={'100%'}></img>
}

export default FigmaImage
