import { TextType } from '../interfaces/FigmaTypes'
import FigmaVariant from '../interfaces/FigmaVariant'
import useIsMobile from './useIsMobile'

export default function useTextVariant(textKey: TextType) {
  const isMobile = useIsMobile()
  let variantStyle = isMobile ? textKey.variants[FigmaVariant.MOBILE] : textKey.variants[FigmaVariant.DESKTOP]
  if (!variantStyle) variantStyle = textKey.variants[FigmaVariant.DESKTOP] || textKey.variants[FigmaVariant.MOBILE]
  return variantStyle
}
