import { memo } from 'react'
import styled from 'styled-components'
import StyledBox from '../moesia/components/StyledBox'
import { Images } from '../figma/helpers/imageHelper'
import ImageContainer from '../moesia/components/ImageContainer'
import HomeHeaderTextComponent from './HomeHeaderTextComponent'
import Spacings from 'src/figma/tokens/Spacings'

const HomeLandingMobile: React.FC = () => (
  <StyledBox fullWidth align="center" top={Spacings.huge}>
    <StyledHeadingCircle fullWidth align="center">
      <ImageContainer imageKey={Images.mobileCenterCircle} />
    </StyledHeadingCircle>
    <StyledBox fullWidth align="center" alignText="center">
      <HomeHeaderTextComponent />
    </StyledBox>
  </StyledBox>
)

export default memo(HomeLandingMobile)

const StyledHeadingCircle = styled(StyledBox)`
  top: -1px;
  flex-basis: 20%;
`
