import { Texts } from '../figma/helpers/TextRepository'
import { TextKeyListType } from './TextKeyListType'

export const homePageHeaderTextsDesktop = (): TextKeyListType[] => [
  { textKey: Texts.LandPageBHStudio11 },
  { textKey: Texts.LandPageBHStudio2 },
  { textKey: Texts.LandPageBHStudio5 }
]

export const homePageHeaderTextsMobile = (): TextKeyListType[] => [
  { textKey: Texts.LandPageMobBHStudio1 },
  { textKey: Texts.LandPageMobBHStudio2 },
  { textKey: Texts.LandPageMobBHStudio3 }
]
