import useIsMobile from 'src/moesia/hooks/useIsMobile'
import { FigmaImageType } from '../interfaces/FigmaTypes'
import FigmaVariant from '../interfaces/FigmaVariant'

export default function useImageVariant(imageKey: FigmaImageType): { width: any; height: any } {
  const isMobile = useIsMobile()
  let variantStyle = isMobile ? imageKey.variants[FigmaVariant.MOBILE] : imageKey.variants[FigmaVariant.DESKTOP]
  if (!variantStyle) variantStyle = imageKey.variants[FigmaVariant.DESKTOP] || imageKey.variants[FigmaVariant.MOBILE]
  return variantStyle as { width: any; height: any }
}
