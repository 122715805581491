import React, { memo } from 'react'
import StyledBox from 'src/figma/components/StyledBox'
import styled from 'styled-components'
import ImageContainer from 'src/moesia/components/ImageContainer'
import { Images } from 'src/figma/helpers/imageHelper'
import ZIndex from 'src/figma/tokens/ZIndex'
import HomeReachUsContent from './HomeReachUsContent'
import Spacings from '../figma/tokens/Spacings'

const HomeReachUsComponent: React.FC = () => (
  <Wrapper fullWidth align="flex-start" top bottom spacing={Spacings.max}>
    <ImageContainerWrapper fullWidth imageKey={Images.landPageYouCan} />
    <StyledBox left={Spacings.medium} right={Spacings.max}>
      <HomeReachUsContent />
    </StyledBox>
  </Wrapper>
)

export default memo(HomeReachUsComponent)

const Wrapper = styled(StyledBox)`
  position: relative;
  height: 100%;
`

const ImageContainerWrapper = styled(ImageContainer)`
  img {
    position: absolute;
    z-index: ${ZIndex.negative};
    height: 100%;
    width: 100vw;
    object-fit: cover;
    top: -${Spacings.minimum};
    left: 0;
  }
`
