import * as React from 'react'

import ButtonPrimaryHoverStyled from './ButtonPrimaryHoverStyled'

type ButtonPrimaryHoverProps = any

const ButtonPrimaryHover: React.FC<ButtonPrimaryHoverProps> = (props) => (
  <ButtonPrimaryHoverStyled {...props}>{props.children ? props.children : 'Company'}</ButtonPrimaryHoverStyled>
)

export default ButtonPrimaryHover
